import { render, staticRenderFns } from "./ssh-file.vue?vue&type=template&id=4672f0d5&scoped=true&"
import script from "./ssh-file.vue?vue&type=script&lang=js&"
export * from "./ssh-file.vue?vue&type=script&lang=js&"
import style0 from "./ssh-file.vue?vue&type=style&index=0&id=4672f0d5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4672f0d5",
  null
  
)

export default component.exports